import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';

function NgSwitchInfo (props) {

    const promoCode = props.promoCode !== null  && props.promoCode !== undefined ? props.promoCode : '';
    const signUpLink = promoCode !== null && promoCode !== undefined ? `/sign-up/promo/${promoCode}` : '/sign-up';

    return (
        <LayoutPanel background={props.panelBackground}
                     padding={props.panelPadding} 
                     {...props}>
            <div className="container">
                <div className="row mer-panel justify-content-around align-items-middle">

                    <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg">
                        <div className="content text-center">
                            <h3>Ready to switch?</h3>
                            <p>Switching to Kleenheat takes just a few minutes online. Take advantage of this exclusive offer today.</p>
                            <a href={signUpLink} className="mer-button mer-button--primary">Switch now</a>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutPanel>
    )
}

export default NgSwitchInfo;